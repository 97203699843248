import React from 'react'
import { graphql } from 'gatsby'
import { Container, Flex } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const styles = {
  heroContainer: {
    bg: `#F6F7FA`,
    pt: '100px',
  },
}
const PelvicHealthPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const collection = content['ailments']['collection'];

  return (
    <Layout {...props}>
      <Seo title='Pelvic Health Phyiscal Therapy' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Content content={content['main-content']} />
      {collection && (
        <Container>
          <Reveal
            effect={'fadeInRight'}
            duration={1.5}
          >
            <ul>
              {collection.map(({ text }, index) => (
                <li key={index} >
                  <ContentText content={text} />
                </li>
              ))}
            </ul>
          </Reveal>
        </Container>
      )}
      <Divider space='5' />
      <Container>
        <Flex sx={{ justifyContent: 'center' }}>
          <ContentButtons content={content['call-to-action']['buttons']} />
        </Flex>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query pelvicHealthPageBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/services/pelvic-health", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PelvicHealthPage
